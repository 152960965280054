import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { notificationError } from '../../helpers/notification';
import ProjectExplorer from '../project-explorer/ProjectExplorer';
import { selectSelectedFolder } from '../../redux/slices/app/folder.slice';

const BaseLineExplorerModal = forwardRef((props, ref) => {
    const [isVisible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const folderSelected = useSelector(selectSelectedFolder)
    // const [selectedMode, setSelectedMode] = useState('archive');
    const { t } = useTranslation('translation', { keyPrefix: 'baseline_explorer_modal' });

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = useCallback(async () => {
        if (selectedItem) {
            if (!selectedItem.rootActivityId) {
                notificationError(t('planning_choice'), t('please_select_a_planning_not_a_folder'))
            } else {
                props.onPlanningChosen(selectedItem)
                setVisible(false);
            }
        }
    }, [selectedItem]);

    return (
        <Modal
                title={t('chose_a_specific_planning')}
                centered
                open={isVisible}
                onOk={() => onOk()}
                onCancel={onCancel}
                width={window.innerWidth * 0.65}
                bodyStyle={{padding: 0, height: window.innerHeight * 0.80, overflow: 'auto'}}
                destroyOnClose
                keyboard={false}
            >
                <ProjectExplorer folder={folderSelected} onlyExplorer onItemSelected={(item) => setSelectedItem(item)}/>
            </Modal>
    );
});

export default BaseLineExplorerModal;
