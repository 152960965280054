import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomFieldService from '../../services/custom-field.service';
import { CustomFieldsMenuContext } from '../../contexts/custom-fields/CustomFieldsMenuContext';
import { requestError } from '../../helpers/notification';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';
import { refreshPlanningCustomFields } from '../../redux/thunks/planning-slice.thunk';

const CustomFieldDeleteForm = ({ customField }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'modal_custom_fields' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { setModalContent, setShouldUpdatePlanning } = useContext(CustomFieldsMenuContext);
    const planningSelected = useSelector(selectPlanningSelected);

    const submitForm = () => {
        setLoading(true);
        CustomFieldService.deleteCustomField(planningSelected.id, customField.id)
            .then(() => {
                setLoading(false);
                dispatch(refreshPlanningCustomFields('delete', {id: customField.id}));
                setShouldUpdatePlanning(true);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('deleting_error'));
            });
    };

    return (
        <Form name="period_remove_form" onFinish={submitForm}>
            <p>
                {t('delete_confirmation')} <b>{customField.name}</b> ?
            </p>

            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={() => setModalContent(null)}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('confirm')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

CustomFieldDeleteForm.propTypes = {
    customField: PropTypes.object,
};

CustomFieldDeleteForm.defaultProps = {
    customField: null,
};

export default CustomFieldDeleteForm;
