import styled from "styled-components";

const TabContainer = styled.div`
    .ant-tabs-tab.ant-tabs-tab-active,
    .ant-tabs-tab-active .ant-typography,
    .ant-tabs-tab-active .ant-tabs-tab-remove,
    .ant-tabs-tab-active .ant-typography-edit {
        color: rgba(251, 190, 63, 1) !important;
        background: rgba(0, 0, 0, 1) !important;
    }
    .ant-tabs-tab {
        background: rgba(251, 190, 63, 1) !important;
        color: rgba(0, 0, 0, 1) !important;
    }
    .ant-typography-edit {
        display: flex !important;
        color: rgba(0, 0, 0, 1);
    }
    .ant-typography-edit-content {
        margin-bottom: 0px !important;
    }
    .ant-typography {
        display: flex;
        align-items: center;
    }
    .ant-typography-edit-content textarea {
        height: 24px;
        min-height: 24px;
        padding: 0 !important;
    }
    .ant-tabs-nav-add {
        padding: 0px !important;
        min-width: unset !important;
    }
    .ant-tabs-tab-remove {
        display: none !important;
    }
    .ant-tabs-tab .anticon {
        margin-right: 0px;
    }
`;

export default TabContainer;