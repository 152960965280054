import { Card, Checkbox, Collapse, Radio, Select } from 'antd';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useMemo } from 'react';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';
import {
    NEED_CHART_DATA,
    NEED_CHART_TYPE,
    NEED_TYPE,
    NEED_VISIBILITY,
    NEED_HISTOGRAM_TYPE,
    SLICE_DATA_TYPE,
} from '../../constants/Needs';
import { filterTreeByCondition } from '../../helpers/tree-helper';
import TreeCheckableList from '../common/list/TreeCheckableList';
import { saveScrollState } from '../plannings/gantt_events';
import { selectTimeUnits } from '../../redux/slices/app/calendar.slice';
import  { updateChartOptions } from '../../redux/thunks/need-chart-slice.thunk';
import { selectChartData, selectChartOptions, selectNeedList, setNeedList } from '../../redux/slices/chart/need-chart.slice';
import { selectTabPreferences, updateTabPreferences } from '../../redux/slices/app/tab.slice';

const ChartOptions = () => {
    const dispatch = useDispatch();	
    const needTranslation = useTranslation('translation', { keyPrefix: 'needs' }).t;
    const histogramTranslation = useTranslation('translation', { keyPrefix: 'histogram' }).t;
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;
    const chartData = useSelector(selectChartData);
    const chartOptions = useSelector(selectChartOptions);
    const needList = useSelector(selectNeedList);
    const timeUnits = useSelector(selectTimeUnits)
    const tabPreferences = useSelector(selectTabPreferences);
    const globalNeedsTree = useMemo(
        () =>
            chartOptions
                ? [
                      filterTreeByCondition(
                          globalNeedList[0],
                          (node) => node.needType === chartOptions.needType,
                          (leaf) => ({ checkable: !leaf.isLevel, ...leaf })
                      ),
                  ]
                : [],
        [globalNeedList, chartOptions?.needType]
    );
    const planningNeedsTree = useMemo(() => {
        if (planningNeedList && planningNeedList.length > 0 && chartOptions) {
            console.log("��� ~ ChartOptions ~ planningNeedsTree:", chartOptions)
            return [
                filterTreeByCondition(
                    planningNeedList[0],
                    (node) => node.needType === chartOptions.needType,
                    (leaf) => ({ checkable: !leaf.isLevel, ...leaf })
                ),
            ];
        }
        return [];
    }, [planningNeedList, chartOptions?.needType]);
    console.log("🚀 ~ ChartOptions ~ globalNeedsTree:", globalNeedsTree, planningNeedsTree)

    const globalSelectedNeedsIds = useMemo(() => {
        const selectedIds = needList.filter((i) => i.selected && i.visibility === NEED_VISIBILITY.GLOBAL).map((i) => i.id.toString());
        return selectedIds;
    }, [needList]);
    const planningSelectedNeedsIds = useMemo(() => {
        const selectedIds = needList.filter((i) => i.selected && i.visibility === NEED_VISIBILITY.PLANNING).map((i) => i.id.toString());
        return selectedIds;
    }, [needList]);

    // const globalConsummableNeeds = useMemo(
    //     () => getArrayInListByCondition(globalNeedList, 'needType', NEED_TYPE.CONSUMMABLE),
    //     [globalNeedList]
    // );

    const saveScroll = useCallback(async () => {
        if (chartData?.length > 0) {
            await saveScrollState();
        }
    }, [chartData]);

    const handleSelectedNeedsChange = useCallback(
        async (selectedIds, visibility) => {
            console.log('SAVE SCROLL NEEDS');
            await saveScroll();
            const newNeedList = cloneDeep(needList);
            // const selectedNeedList = [];
            needList.forEach((need, index) => {
                if (need.visibility === visibility) {
                    newNeedList[index].selected = selectedIds.indexOf(need.id) > -1;
                }
            });
            // const selectedNeeds = needList.filter((i) => i.selected).map((i) => i.id);
            // if (selectedNeeds.length > 0) {
                dispatch(updateTabPreferences({
                    gantt_histogram_needs: {
                        ...tabPreferences.gantt_histogram_needs,
                        [chartOptions.needType]: selectedIds,
                    },
                }));
            // }
            dispatch(setNeedList(newNeedList));
        },
        [needList, chartData]
    );

    const updateChartOption = useCallback(
        async (newOptions) => {
            console.log('SAVE SCROLL OPTIONS');

            await saveScroll();
            await 
           dispatch(updateChartOptions(newOptions));
        },
        [chartData]
    );

    

    return (
        chartData.length > 0 && chartOptions && (
            <div className="w-full h-full p-2 flex flex-col flex-grow overflow-auto">
                <Card size="small" className="mb-2" title={histogramTranslation('histogram')}>
                    <div className="flex justify-between mb-1">
                        <div>
                            <label>{histogramTranslation('chart_type')}:</label>
                        </div>
                        <div>
                            <Radio.Group
                                value={chartOptions.chartType}
                                onChange={(e) => updateChartOption({ chartType: e.target.value })}
                                size="small"
                            >
                                <Radio.Button value={NEED_CHART_TYPE.BAR}>
                                    {histogramTranslation('chart_type_bar')}
                                </Radio.Button>
                                <Radio.Button value={NEED_CHART_TYPE.LINE}>
                                    {histogramTranslation('chart_type_line')}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    {chartOptions.chartType === NEED_CHART_TYPE.BAR && (
                        <div className="flex justify-between mb-1">
                            <div>
                                <label>{histogramTranslation('histogram_type')}:</label>
                            </div>
                            <div>
                                <Radio.Group
                                    value={chartOptions.histogramType}
                                    onChange={(e) => updateChartOption({ histogramType: e.target.value })}
                                    size="small"
                                >
                                    <Radio.Button value={NEED_HISTOGRAM_TYPE.STACKED}>
                                        {histogramTranslation('histogram_type_stacked')}
                                    </Radio.Button>
                                    <Radio.Button value={NEED_HISTOGRAM_TYPE.UNSTACKED}>
                                        {histogramTranslation('histogram_type_unstacked')}
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                    )}
                    {chartOptions.needType === NEED_TYPE.NON_CONSUMMABLE && (
                        <>
                            <div className="flex justify-between mb-1">
                                <div>
                                    <label>{histogramTranslation('data_type')}:</label>
                                </div>
                                <div>
                                    <Radio.Group
                                        value={chartOptions.sliceDataType}
                                        onChange={(e) => updateChartOption({ sliceDataType: e.target.value })}
                                        size="small"
                                    >
                                        <Radio.Button value={SLICE_DATA_TYPE.QUANTITY}>
                                            {histogramTranslation('shown_data_quantity')}
                                        </Radio.Button>
                                        <Radio.Button value={SLICE_DATA_TYPE.EFFORT}>
                                            {histogramTranslation('shown_data_effort')}
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                            {chartOptions.sliceDataType === SLICE_DATA_TYPE.EFFORT && (
                                <div className="flex justify-between mb-1">
                                    <div>
                                        <label>{histogramTranslation('effort_time_unit')}:</label>
                                    </div>
                                    <div>
                                        <Select
                                            style={{ width: '200px' }}
                                            defaultValue={chartOptions.effortTimeUnitId}
                                            options={timeUnits.map((timeUnit) => ({
                                                label: timeUnit.name,
                                                value: timeUnit.id,
                                            }))}
                                            onChange={(value) => {
                                                updateChartOption({ effortTimeUnitId: value });
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    <div className="flex justify-between mb-1">
                        <div>
                            <label>{histogramTranslation('shown_data')}:</label>
                        </div>
                        <div>
                            <Radio.Group
                                value={chartOptions.dataType}
                                onChange={(e) => updateChartOption({ dataType: e.target.value })}
                                size="small"
                            >
                                <Radio.Button value={NEED_CHART_DATA.INSTANTANEOUS}>
                                    {histogramTranslation('data_type_instantaneous')}
                                </Radio.Button>
                                {((chartOptions.needType === NEED_TYPE.CONSUMMABLE &&
                                    chartOptions.sliceDataType === SLICE_DATA_TYPE.QUANTITY) ||
                                    (chartOptions.needType === NEED_TYPE.NON_CONSUMMABLE &&
                                        chartOptions.sliceDataType === SLICE_DATA_TYPE.EFFORT)) && (
                                    <Radio.Button value={NEED_CHART_DATA.CUMULATIVE}>
                                        {histogramTranslation('data_type_cumulative')}
                                    </Radio.Button>
                                )}
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div>
                            <label>{histogramTranslation('filter_by_gantt')}</label>
                        </div>
                        <div>
                            <Checkbox
                                checked={chartOptions.isGanttActivityFiltered}
                                onChange={(e) => updateChartOption({ isGanttActivityFiltered: e.target.checked })}
                            />
                        </div>
                    </div>
                </Card>
                <Card size="small" className="flex-grow" title={needTranslation('needs')}>
                    <div className="flex justify-between mb-1">
                        <div>
                            <label>{needTranslation('need_type')}:</label>
                        </div>
                        <div>
                            <Radio.Group
                                value={chartOptions.needType}
                                onChange={(e) => 
                                    updateChartOption({
                                        needType: e.target.value,
                                        dataType: NEED_CHART_DATA.INSTANTANEOUS, 
                                        sliceDataType: SLICE_DATA_TYPE.QUANTITY
                                    })
                                }
                                size="small"
                            >
                                <Radio.Button value={NEED_TYPE.NON_CONSUMMABLE}>
                                    {needTranslation('non_consummable')}
                                </Radio.Button>
                                <Radio.Button value={NEED_TYPE.CONSUMMABLE}>
                                    {needTranslation('consummable')}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <Collapse defaultActiveKey={chartOptions.shownNeedsPanel} onChange={(keys) => updateChartOption({shownNeedsPanel : keys})} >
                        <Collapse.Panel forceRender header={needTranslation('global_needs_library')} key="global">
                            <TreeCheckableList
                                list={globalNeedsTree}
                                defaultChecked={globalSelectedNeedsIds}
                                handleCheckedItemChange={(e) => handleSelectedNeedsChange(e, NEED_VISIBILITY.GLOBAL)}
                                labelField="name"
                            />
                        </Collapse.Panel>
                        <Collapse.Panel forceRender header={needTranslation('planning_needs_library')} key="planning">
                            <TreeCheckableList
                                list={planningNeedsTree}
                                defaultChecked={planningSelectedNeedsIds}
                                handleCheckedItemChange={(e) => handleSelectedNeedsChange(e, NEED_VISIBILITY.PLANNING)}
                                labelField="name"
                            />
                        </Collapse.Panel>
                    </Collapse>
                    {/* {needList.map((need, index) => (
                    <div key={generateString(8)} className=" w-full hover:bg-primary p-1 items-center cursor-pointer ">
                        <Checkbox
                            className="mr-2 w-full"
                            checked={need.selected}
                            onChange={(e) => handleToggleSelectedNeed(e, index)}
                        >
                            <div className="flex">
                                <div className={`mr-2 ${need.visibility === NEED_VISIBILITY.PLANNING ? 'italic' : ''}`}>
                                    {need.name}
                                </div>
                                <div style={{ height: '20px', width: '20px', background: need.color }} />
                            </div>
                        </Checkbox>
                    </div>
                ))} */}
                </Card>
            </div>
        )
    );
};

export default ChartOptions;
