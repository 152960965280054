import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'antd';
import styled from 'styled-components';

const PillButton = styled(Button)`
    font-size: 0.6875rem;
    width: 33.33333%;
    padding: 4px 0;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`;

const Pill = ({ active, children, onClick }) => (
    <PillButton active={active} htmlType="button" type={active ? 'primary' : 'default'} onClick={onClick}>
        {children}
    </PillButton>
);

Pill.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
};

Pill.defaultProps = {
    active: false,
};

export default Pill;
