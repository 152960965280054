import { Card, Radio } from 'antd';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';
import {
    NEED_CHART_DATA,
    NEED_TYPE,
    NEED_VISIBILITY,
    SLICE_DATA_TYPE,
} from '../../constants/Needs';
import { filterTreeByCondition } from '../../helpers/tree-helper';
import TreeCheckableList from '../common/list/TreeCheckableList';
import { selectChartData, selectChartOptions, selectNeedList, setNeedList } from '../../redux/slices/chart/need-chart.slice';
import { updateChartOptions } from '../../redux/thunks/need-chart-slice.thunk';

const HistogramTabChartOptions = () => {
    const dispatch = useDispatch();	
    const needTranslation = useTranslation('translation', { keyPrefix: 'needs' }).t;
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;
    const chartData = useSelector(selectChartData);
    const chartOptions = useSelector(selectChartOptions);
    const needList = useSelector(selectNeedList);
    const globalNeedsTree = useMemo(
        () => chartOptions ? [
            filterTreeByCondition(
                globalNeedList[0],
                (node) => node.needType === chartOptions?.needType,
                (leaf) => ({ checkable: !leaf.isLevel, ...leaf })
            ),
        ] : [],
        [globalNeedList, chartOptions?.needType]
    );
    const planningNeedsTree = useMemo(() => {
        if (planningNeedList && planningNeedList.length > 0 && chartOptions) {
            return [
                filterTreeByCondition(
                    planningNeedList[0],
                    (node) => node.needType === chartOptions.needType,
                    (leaf) => ({ checkable: !leaf.isLevel, ...leaf })
                ),
            ];
        }
        return [];
    }, [planningNeedList, chartOptions?.needType]);

    const globalSelectedNeedsIds = useMemo(() => {
        const selectedIds = needList.filter((i) => i.selected && i.visibility === NEED_VISIBILITY.GLOBAL).map((i) => i.id.toString());
        return selectedIds;
    }, [needList]);
    const planningSelectedNeedsIds = useMemo(() => {
        const selectedIds = needList.filter((i) => i.selected && i.visibility === NEED_VISIBILITY.PLANNING).map((i) => i.id.toString());
        return selectedIds;
    }, [needList]);
    
    const handleSelectedNeedsChange = useCallback(
        (selectedIds, visibility) => {
        console.log("🚀 ~ file: HistogramTabChartOptions.jsx:107 ~ HistogramTabChartOptions ~ selectedIds:", selectedIds)
            const newNeedList = cloneDeep(needList);
            needList.forEach((need, index) => {
                if (need.visibility === visibility) {
                    newNeedList[index].selected = selectedIds.indexOf(need.id) > -1;
                }
            });
            console.log("QQQQQ🚀 ~ file: HistogramTabChartOptions.jsx:52 ~ HistogramTabChartOptions ~ newNeedList:", newNeedList)
            dispatch(setNeedList(newNeedList));
        },
        [needList]
    );

    const updateChartOption = useCallback((newOptions) => {
        dispatch(updateChartOptions(newOptions));
    }, [chartData]);

    return chartData.length > 0 && chartOptions && (
        <div className="w-full h-full p-2 flex flex-col flex-grow overflow-auto">
            <Card size="small" className="flex-grow" title={needTranslation('needs')}>
                <div className="flex flex-col justify-between mb-1">
                    <div>
                        <label>{needTranslation('need_type')}:</label>
                    </div>
                    <div>
                        <Radio.Group
                            value={chartOptions.needType}
                            onChange={(e) => 
                                updateChartOption({
                                    needType: e.target.value,
                                    dataType: NEED_CHART_DATA.INSTANTANEOUS, 
                                    sliceDataType: SLICE_DATA_TYPE.QUANTITY
                                })
                            }
                            size="small"
                        >
                            <Radio.Button value={NEED_TYPE.NON_CONSUMMABLE}>
                                {needTranslation('non_consummable')}
                            </Radio.Button>
                            <Radio.Button value={NEED_TYPE.CONSUMMABLE}>{needTranslation('consummable')}</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
                <Card size="small" className="my-1" title={needTranslation('global_needs_library')}>
                    <TreeCheckableList
                        list={globalNeedsTree}
                        defaultChecked={globalSelectedNeedsIds}
                        handleCheckedItemChange={(e) => handleSelectedNeedsChange(e, NEED_VISIBILITY.GLOBAL)}
                        labelField="name"
                    />
                </Card>
                <Card size="small" title={needTranslation('planning_needs_library')}>
                    <TreeCheckableList
                        list={planningNeedsTree}
                            defaultChecked={planningSelectedNeedsIds}
                        handleCheckedItemChange={(e) => handleSelectedNeedsChange(e, NEED_VISIBILITY.PLANNING)}
                        labelField="name"
                    />
                </Card>
            </Card>
        </div>
    );
};

export default HistogramTabChartOptions;
