import { AntDValueSelector } from '@react-querybuilder/antd';
import { isFieldExist } from '../FilterUtils';
/* eslint-disable */
const CustomFieldEditor = (props) => {
  const status =
  isFieldExist(props.options, props.value)
  ? ''
  : 'error';
  return <AntDValueSelector {...props} status={status} dropdownStyle={{borderWidth: `${status === 'error' ? '4px': '1px'}`}} />;
};

export default CustomFieldEditor;