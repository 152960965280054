import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Modal } from "antd";
import axios from '../../services/api';
import authService from '../../services/auth.service';

const SessionInfo = () => {
    const {t} = useTranslation('translation', {keyPrefix: 'session_info'})
    const [lastRefreshTime, setLastRefreshTime] = useState(Date.now());
    const [remainInactivityTime, setRemainInactivityTime] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (lastRefreshTime) {
            const counter = setInterval(() => {
                // const delayFromLastrefreshToken = moment().diff(moment(lastRefreshTime), 'seconds');
                const tokenExpiredDate = moment(Number(localStorage.getItem('expires_date')));
                const delayTokenExpired = moment(tokenExpiredDate).diff(moment(), 'seconds');
                const refreshTokenExpiredDate = moment(Number(localStorage.getItem('refresh_expires_date')));
                const delayRefreshTokenExpired = moment(refreshTokenExpiredDate).diff(moment(), 'seconds');
                if (delayTokenExpired <= 0 && delayRefreshTokenExpired >= 0) {
                    let minutes = parseInt(delayRefreshTokenExpired / 60, 10);
                    let secondes = parseInt(delayRefreshTokenExpired % 60, 10);

                    minutes = minutes < 10 ? '0'.concat(minutes) : minutes;
                    secondes = secondes < 10 ? '0'.concat(secondes) : secondes;
                    setRemainInactivityTime(`${minutes}min ${secondes}s`);
                } else if (delayRefreshTokenExpired < 0 || delayTokenExpired < 0) {
                    setRemainInactivityTime(t('disconnected'));
                } else {
                    setRemainInactivityTime(null);
                }
            }, 1000);
            return () => clearInterval(counter);
        }
        return () => {};
    }, [lastRefreshTime]);

    useEffect(() => {
      if (remainInactivityTime === t('disconnected')) {
        Modal.warning({
          title: t('logged_out_text'),
          content: (<p>{t('redirection_text')}</p>),
          onOk: () => {
            authService.logout();
            history.push('/login');
            window.location.reload();
          }
        })
      }
    }, [remainInactivityTime])

    useEffect(() => {
        axios.interceptors.request.use((config) => {
            if (
                config.url.includes('refresh') ||
                (config.url.includes('sessions') && !config.url.includes('sessions/'))
            ) {
                setLastRefreshTime(Date.now());
            }
            return config;
        });
    }, []);

    return (
        <>
            {remainInactivityTime && (
                <>
                    <label>{t('remain_inactivity_time')} </label>
                    <span>&nbsp;{remainInactivityTime}</span> &nbsp; &nbsp;
                </>
            )}
        </>
    );
};

export default SessionInfo;
