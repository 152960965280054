import React from 'react';
import { PropTypes } from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';

const DeleteButton = ({ onClick, className }) => (
    <DeleteOutlined
        onClick={onClick}
        className={`${className} p-2 rounded-full bg-red-600 hover:bg-red-400 text-white transition-colors duration-300`}
    />
);

DeleteButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
};

DeleteButton.defaultProps = {
    onClick: () => {},
    className: '',
};

export default DeleteButton;
