import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Modal, Spin } from 'antd';
import AuthService from '../../services/auth.service';

const BackModalVersion = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [versions, setVersions] = useState([]);
    // KEYBOARD SHORTCUT REPLANIFIER
    const showModal = () => {
        setIsModalVisible(true);
    };

    useHotkeys('ctrl+alt+v', (e) => {
        e.preventDefault();
        showModal();
    });

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const onModalVisible = async () => {
            if (isModalVisible === true) {
                setIsLoading(true);
                const versionList = [];
                // Front
                versionList.push({
                    label: 'FRONT',
                    version: window._env_.VERSION,
                });
                // wallet
                versionList.push({
                    label: 'WALLET',
                    version: await AuthService.version(window._env_.REACT_APP_WALLET_API),
                });
                if (window._env_.REACT_APP_ACTIVITY_API) {
                    versionList.push({
                        label: 'ACTIVITY',
                        version: await AuthService.version(window._env_.REACT_APP_ACTIVITY_API),
                    });
                }
                if (window._env_.REACT_APP_USERS_API) {
                    versionList.push({
                        label: 'USERS',
                        version: await AuthService.version(window._env_.REACT_APP_USERS_API),
                    });
                }
                if (window._env_.REACT_APP_TEAMS_API) {
                    versionList.push({
                        label: 'TEAMS',
                        version: await AuthService.version(window._env_.REACT_APP_TEAMS_API),
                    });
                }
                if (window._env_.REACT_APP_FOLDER_API) {
                    versionList.push({
                        label: 'FOLDER',
                        version: await AuthService.version(window._env_.REACT_APP_FOLDER_API),
                    });
                }
                if (window._env_.REACT_APP_CALENDAR_API) {
                    versionList.push({
                        label: 'CALENDAR',
                        version: await AuthService.version(window._env_.REACT_APP_CALENDAR_API),
                    });
                }
                if (window._env_.REACT_APP_TEMPLATE_API) {
                    versionList.push({
                        label: 'TEMPLATE',
                        version: await AuthService.version(window._env_.REACT_APP_TEMPLATE_API),
                    });
                }

                setVersions(versionList);
                setIsLoading(false);
            }
        };
        onModalVisible();
    }, [isModalVisible]);

    return (
        <>
            <Modal title="Back Version" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Spin spinning={isLoading} tip="Récupération des versions">
                    <p>Version des micro-services : </p>
                    {versions.map((v) => (
                        <p key={`${v.label}`}>
                            {' '}
                            - Service [{v.label}] version : {v.version}
                        </p>
                    ))}
                </Spin>
            </Modal>
        </>
    );
};

export default BackModalVersion;
