import React, { forwardRef, useCallback, useImperativeHandle, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Popconfirm, Row } from 'antd';
import FolderService from '../../services/folder.service';
import { getPlanningAccess, USER_ACCESS } from '../../helpers/planning';
import ProjectExplorer from '../project-explorer/ProjectExplorer';
import { selectSelectedFolder, setSelectedFolder } from '../../redux/slices/app/folder.slice';
import { selectCurrentUser, selectUserPreferences, updateUserPreferences } from '../../redux/slices/app/user.slice';
import { requestPlanningsInFolderForMode } from '../../redux/utils/PlanningSliceUtils';
import { updatePlanning } from '../../redux/thunks/planning-slice.thunk';
import { selectModeSelected, setModeSelected } from '../../redux/slices/app/planning.slice';

const ExplorerModal = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const folderSelected = useSelector(selectSelectedFolder);
    const modeSelected = useSelector(selectModeSelected);  
    const currentUser = useSelector(selectCurrentUser);  
    const userPreferences = useSelector(selectUserPreferences);  
    const [isVisible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('translation', { keyPrefix: 'explorer_modal' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
    };

    const onOk = useCallback(async () => {
        if (selectedItem) {
            if (!selectedItem.rootActivityId) {
                setIsLoading(true);
                dispatch(setSelectedFolder(selectedItem));
                await dispatch(updateUserPreferences({ open_folder: selectedItem, open_planning: null }));
                const plannings = await requestPlanningsInFolderForMode(selectedItem.id, modeSelected);
                const planningWithAccess = plannings.filter(
                    (p) => getPlanningAccess(p, currentUser) !== USER_ACCESS.NONE
                );
                if (planningWithAccess.length) {
                    await dispatch(updatePlanning({planning: planningWithAccess.first()}));
                } else {
                    await dispatch(updatePlanning({planning: null}));
                }
                setIsLoading(false);
                setVisible(false);
            } else {
                let selectedFolder;
                if (selectedItem.folderId) {
                    selectedFolder = await FolderService.showFolder(selectedItem.folderId);
                    dispatch(setSelectedFolder(selectedFolder));
                }
                await dispatch(updateUserPreferences({
                    open_folder: selectedFolder || userPreferences.open_folder,
                    open_mode: selectedItem.mode,
                    // open_planning: { ...userPreferences.open_planning, [selectedItem.mode]: selectedItem },
                }));
                dispatch(setModeSelected(selectedItem.mode));
                await dispatch(updatePlanning({planning: selectedItem}));
                setVisible(false);
            }
        }
    }, [selectedItem]);
    const handleDeletePlanning = (id) => {
        const deletePlanning =
            modeSelected === 'sandbox' ? FolderService.deleteSandboxPlanning(id) : FolderService.deletePlanning(id);
        deletePlanning.then(() => {
            console.log('deleted');
        });
    };
    const modalFooter = useMemo(
        () => (
            <Row className="flex justify-between">
                <div>
                    {selectedItem?.rootActivityId &&
                        [USER_ACCESS.NONE, USER_ACCESS.READ].indexOf(selectedItem?.access) === -1 && (
                            <Popconfirm
                                title={t('are_you_sure_you_want_to_delete')}
                                okText={generalTranslation('yes')}
                                cancelText={generalTranslation('no')}
                                onConfirm={() => handleDeletePlanning(selectedItem.id)}
                            >
                                <Button type="danger">{t('delete_planning')}</Button>
                            </Popconfirm>
                        )}
                </div>
                <div>
                    <Button type="secondary" className="mr-2" onClick={onCancel}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button type="primary" loading={isLoading} disabled={!selectedItem} onClick={onOk}>
                        {generalTranslation('ok')}
                    </Button>
                </div>
            </Row>
        ),
        [selectedItem, isLoading]
    );

    return (
        <Modal
            title={t('folder_explorer')}
            className="explorer-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            width={window.innerWidth * 0.65}
            bodyStyle={{ padding: 0, height: window.innerHeight * 0.8, overflow: 'auto' }}
            keyboard={false}
            destroyOnClose
            footer={modalFooter}
        >
            <ProjectExplorer
                specificMode={[modeSelected]}
                folder={folderSelected}
                onlyExplorer
                onItemSelected={(item) => setSelectedItem(item)}
            />
        </Modal>
    );
});

export default ExplorerModal;
