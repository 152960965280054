import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { Input, Button, Checkbox, List, Popover, Space } from 'antd';
import { CaretDownOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import AddFilterForm from './AddFilterForm';
import { generateString } from '../../helpers/string-helper';

const FilterListContainer = styled.div`
    .ant-list-header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 100;
    }
`;
const CustomCheckBoxContainer = styled.span`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: green;
        border-color: green;
    }
`;

const FilterList = ({
    setCheckedFilters,
    checkedFilters,
    currentFilterIndex,
    setCurrentFilterIndex,
    filtersData,
    validationData,
    onAddNewFilter,
    onlyActiveFilters,
    setOnlyActiveFilters,
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'filter_dropdown' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [isAddFilterFormOpen, setIsAddFilterFormOpen] = useState(false);
    const [filters, setFilters] = useState([]);
    const [isSortedBy, setIsSortedBy] = useState('name');
    // const [isDateSorted, setIsDateSorted] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        let sortedFilters = filtersData.map((i, index) => ({ ...i, originalIndex: index }));
        
        if (searchFilter) {
            sortedFilters = sortedFilters.filter((i) => i.name.toLowerCase().includes(searchFilter.toLowerCase()));
        }
        if (isSortedBy) {
            sortedFilters = orderBy(sortedFilters, [isSortedBy === 'name' ? (i) => i.name.toLowerCase() : (i) => i.created], ['asc'])
        }
         
        setFilters(sortedFilters);
    }, [filtersData, isSortedBy, searchFilter]);
    return (
        <FilterListContainer>
            <Popover
                content={
                    <AddFilterForm
                        onNewFilter={(data) => {
                            setIsAddFilterFormOpen(false);
                            onAddNewFilter(data);
                        }}
                    />
                }
                title="New filter"
                trigger="click"
                placement="bottom"
                open={isAddFilterFormOpen}
                onOpenChange={setIsAddFilterFormOpen}
            >
                <Button className="w-full mb-3" style={{ borderColor: '#000' }}>
                    <label>+ {t('add_filter')}</label>
                </Button>
            </Popover>
            {/* <Checkbox.Group
                style={{ width: '100%' }}
                value={checkedFilters}
                onChange={(checkedValues) => {
                    setCheckedFilters(checkedValues);
                }}
            > */}
                <List
                    style={{ maxHeight: '555px' }}
                    className="overflow-auto"
                    itemLayout="horizontal"
                    size="small"
                    bordered
                    header={
                        <div className="flex flex-col w-full">
                            <div className="mb-1 w-full">
                                <Input
                                    placeholder={generalTranslation('search')}
                                    onChange={(e) => setSearchFilter(e.target.value)}
                                    allowClear
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <Space className="mb-1">
                                <Button
                                    className="flex items-center"
                                    size="small"
                                    shape="round"
                                    type={`${(isSortedBy === 'name') ? 'primary' : 'secondary'}`}
                                    onClick={() => {
                                        setIsSortedBy((oldValue) => oldValue==='name'? null : 'name');
                                    }}
                                >
                                    <CaretDownOutlined /> {generalTranslation('name')}
                                </Button>
                                <Button
                                    className="flex items-center"
                                    size="small"
                                    shape="round"
                                    type={`${(isSortedBy === 'date')? 'primary' : 'secondary'}`}
                                    onClick={() => {
                                        setIsSortedBy((oldValue) => oldValue === 'date' ? null : 'date');
                                    }}
                                >
                                    <CaretDownOutlined /> {t('created')}
                                </Button>
                            </Space>
                            <Space>
                                <Button
                                    shape="round"
                                    size="small"
                                    type={`${onlyActiveFilters ? 'primary' : 'secondary'}`}
                                    onClick={() => {
                                        setCurrentFilterIndex(null);
                                        // setCurrentFilterIndex(
                                        //     filtersData.findIndex((i) => checkedFilters.indexOf(i.id) !== -1)
                                        // );
                                        setOnlyActiveFilters((oldValue) => !oldValue);
                                    }}
                                >
                                    <span className="text-black">{t('active_filters')}</span>
                                </Button>
                            </Space>
                        </div>
                    }
                    dataSource={filters}
                    renderItem={(item) => {
                        const isActive = checkedFilters?.indexOf(item.id) !== -1;
                        const isSelected = item.originalIndex === currentFilterIndex;
                        return (
                            (!onlyActiveFilters || (onlyActiveFilters && isActive)) && (
                                <List.Item
                                    className={`p-1 cursor-pointer ${isSelected ? 'bg-primary' : ''}`}
                                    onClick={() => {
                                        setCurrentFilterIndex(item.originalIndex);
                                    }}
                                    style={{
                                        background: `${isActive && !isSelected ? 'rgba(251, 190, 63, 0.2)' : ''}`,
                                    }}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <CustomCheckBoxContainer>
                                                <Checkbox key={generateString(5)} checked={isActive} onChange={(e) => {
                                                    const {checked} = e.target;
                                                    if (checked) {
                                                        setCheckedFilters((old)=> [...old, item.id]);
                                                    } else {
                                                        setCheckedFilters(checkedFilters.filter(i => i !== item.id));
                                                    }
                                                }}/>
                                            </CustomCheckBoxContainer>
                                        }
                                        title={
                                            <div className="flex justify-between items-center">
                                                <label>{item.name} </label>
                                                {validationData[item.originalIndex]?.invalid && (
                                                    <ExclamationCircleFilled style={{ color: 'red' }} />
                                                )}
                                            </div>
                                        }
                                        description={`${t('created')}: ${moment(item.created).format(
                                            window.dateStringFormat
                                        )}`}
                                    />
                                </List.Item>
                            )
                        );
                    }}
                />
            {/* </Checkbox.Group> */}
        </FilterListContainer>
    );
};

export default FilterList;
