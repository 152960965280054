import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Popconfirm } from 'antd';
import CustomFieldService from '../../services/custom-field.service';
import { CustomFieldsMenuContext } from '../../contexts/custom-fields/CustomFieldsMenuContext';
import LoadingSpin from '../utils/LoadingSpin';
import CustomFieldChoiceEditionForm from './CustomFieldChoiceEditionForm';
import ColorPreview from '../utils/ColorPreview';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
import { ReactComponent as HatchHorizontalSvg } from '../../assets/hatch/horizontal.svg';
import { ReactComponent as HatchVerticalSvg } from '../../assets/hatch/vertical.svg';
import { ReactComponent as HatchLeftSvg } from '../../assets/hatch/left.svg';
import { ReactComponent as HatchRightSvg } from '../../assets/hatch/right.svg';
import { ReactComponent as PlainSvg } from '../../assets/hatch/plain.svg';

import { requestError } from '../../helpers/notification';
import { ALL_JOBS } from '../../constants/Jobs';
import { selectGanttData } from '../../redux/slices/app/planning.slice';
import { refreshPlanningCustomFields } from '../../redux/thunks/planning-slice.thunk';

const ListHeaders = styled.li`
    padding: 0 0 0 8px;
    margin-bottom: 2px;
`;

const PlusIcon = styled(PlusSvg)`
    display: inline;
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    width: 574px;
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const ActionsColumn = styled.span`
    width: 44px;

    div {
        width: 22px;
    }
`;

const CustomFieldChoicesList = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('translation', {keyPrefix: 'modal_custom_fields'})
    const generalTranslation = useTranslation('translation', {keyPrefix: 'general'}).t;
    const [loading, setLoading] = useState(false);
    const { selectedCustomField, setSelectedCustomField, modalContent, setModalContent, setShouldUpdatePlanning } =
        useContext(CustomFieldsMenuContext);
    const { planningCustomFields, modeSelected, canAccess, planningSelected } = useSelector(selectGanttData);

    useEffect(() => {}, [planningCustomFields, modalContent]);

    const addChoice = () => {
        setModalContent({
            title: `${t('custom_fields')} - ${t('add_choice')}`,
            body: <CustomFieldChoiceEditionForm customField={selectedCustomField} />,
        });
    };

    const editChoice = (choiceName) => {
        setModalContent({
            title: `${t('custom_fields')} - ${t('edit_choice')}`,
            body: <CustomFieldChoiceEditionForm customField={selectedCustomField} choiceName={choiceName} />,
        });
    };

    const removeChoice = (choiceName) => {
        setLoading(true);
        CustomFieldService.removeChoice(selectedCustomField.id, choiceName, planningSelected.rootActivityId)
            .then((updatedCustomField) => {
                setLoading(false);
                setSelectedCustomField(updatedCustomField);
                dispatch(refreshPlanningCustomFields('edit', updatedCustomField));
                setShouldUpdatePlanning(true)
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('error_delete_choice'));
            });
    };

    const getHatchLogo = (hatchType) => {
        switch (hatchType) {
            case 'HORIZONTAL':
                return <HatchHorizontalSvg />;
            case 'VERTICAL':
                return <HatchVerticalSvg />;
            case 'BIAI':
                return <HatchLeftSvg />;
            case 'BIAI1':
                return <HatchRightSvg />;
            default:
                return <PlainSvg />;
        }
    };

    if (loading) {
        return <LoadingSpin />;
    }

    const choices = [];
    if (selectedCustomField && selectedCustomField.type.choice) {
        Object.keys(selectedCustomField.type.choices).forEach((choiceName) => {
            const choice = selectedCustomField.type.choices[choiceName];
            // if (choiceName !== ALL_JOBS) {

                choices.push(
                    <li key={`custom-field-${selectedCustomField.id}-choice-${choice.id}`} className="p-0 mb-1">
                        <div className="flex items-stretch">
                            <NameColumn className="flex-1 flex items-center bg-white rounded-l-sm p-2 text-xs">
                                {choiceName !== ALL_JOBS ? choiceName : generalTranslation('all_jobs')}
                                <ColorPreview color={choice.color} marginClass="mx-2" />
                                {choice.hachure && <ColorPreview color={choice.trame} marginClass="mx-2" />}
                                {getHatchLogo(choice.hachure)}
                            </NameColumn>
                            {modeSelected !== 'archive' && canAccess && (
                                <ActionsColumn className="flex items-center">
                                    <div className="bg-white py-2 h-full rounded-r-sm cursor-pointer">
                                        <EditSvg
                                            width="16"
                                            height="16"
                                            onClick={() => editChoice(choiceName)}
                                            className="mx-auto"
                                        />
                                    </div>
                                    {choiceName !== ALL_JOBS && (
                                        <Popconfirm
                                            title={t('confirm_delete_choice')}
                                            okText="Oui"
                                            cancelText="Non"
                                            onConfirm={() => removeChoice(choiceName)}
                                        >
                                            <CloseSvg width="16" height="16" className="cursor-pointer mx-auto" />
                                        </Popconfirm>
                                    )}
                                </ActionsColumn>
                            )}
                        </div>
                    </li>
                );
            // }
        });
    }

    return (
        <div className="max-h-full overflow-y-auto">
            <ul className="pb-8">
                <ListHeaders>
                    <NameColumnHeader>{t('custom_field_type')}</NameColumnHeader>
                </ListHeaders>
                {choices}
            </ul>
            {modeSelected !== 'archive' && selectedCustomField.type.choice && canAccess && (
                <div className="absolute bottom-0 left-0 pl-2 pb-2 bg-transparent">
                    <Button
                        type="secondary"
                        className="block pl-0 ml-2 flex items-center cursor-pointer"
                        onClick={addChoice}
                    >
                        <PlusIcon className="ml-0 mr-3" /> {t('add_choice')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CustomFieldChoicesList;
