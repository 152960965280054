import React from "react";
import { generateString } from "../../helpers/string-helper";

const CustomRectangleWithText = ({ x, y, widthRect, heightRect, text, isCentered }) => {
  // Calculate the position to center the text inside the rectangle

  const textX = isCentered ? x + widthRect / 2 : x;
  const textY = y + heightRect / 2;
  const clipathId = generateString(5);

  return (
      <g width={widthRect} height={heightRect}>
          <defs>
              <clipPath id={clipathId}>
                  <rect x={x} y={y} width={widthRect} height={heightRect} />
              </clipPath>
          </defs>

          {/* Rectangle */}
          <rect
              x={x}
              y={y}
              width={widthRect}
              stroke="#666"
              strokeWidth={1}
              height={heightRect}
              fill="#ffffff"
          >
            <title>{text}</title>
          </rect>

          {/* Centered text */}
          <text
              x={textX}
              width={widthRect}
              y={textY}
              fontSize={12}
              fill="#666"
              textAnchor={isCentered ? 'middle' : 'left'}
              overflow="auto"
              alignmentBaseline="middle"
              clipPath={`url(#${clipathId})`}
          >
              {text}
          </text>
      </g>
  );
};

export default CustomRectangleWithText;