import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DraggableTabNode';


const DraggableTabNode = ({ index, children, moveNode }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};

            if (dragIndex === index) {
                return {};
            }

            return {
                isOver: monitor.isOver(),
                dropClassName: 'dropping',
            };
        },
        drop: (item) => {
            moveNode(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <div
            ref={ref}
            style={{
                marginRight: 8,
            }}
            className={isOver ? dropClassName : ''}
        >
            {children}
        </div>
    );
};

export default DraggableTabNode;
