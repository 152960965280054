import React from 'react';
import CustomRectangleWithText from '../CustomRectangleWithText';
import { generateString } from '../../../helpers/string-helper';

const SCALE_HEIGHT = 35;

const CustomDateRectangles = (props) =>
    props.coordinates.map((data) =>
        data ? (
            <CustomRectangleWithText
                key={generateString(4)}
                x={data.x}
                y={props.isMainScale ? props.height - 2 * SCALE_HEIGHT : props.height - 1 * SCALE_HEIGHT}
                widthRect={data.widthRect}
                heightRect={data.heightRect}
                text={data.text}
                isCentered={data.isCentered}
            />
        ) : (
            <g key={generateString(4)} />
        )
    );

export default CustomDateRectangles
