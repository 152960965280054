import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const SubmitButton = ({ children, loading }) => (
    <Button type="primary" htmlType="submit" loading={loading} className="block w-full h-10 shadow-inner-button">
        {children}
    </Button>
);

SubmitButton.propTypes = {
    children: PropTypes.any.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default SubmitButton;
