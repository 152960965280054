import React from "react";

const CustomizedBar = (props) => {
  const { fill, y, height, width, scaleCoordinates, index, isStacked, dataOrder, dataCount } = props;
  const xCoord = isStacked ? scaleCoordinates[index]?.x : scaleCoordinates[index]?.x + dataOrder * width;
  const barWidth = isStacked ? scaleCoordinates[index]?.widthRect : scaleCoordinates[index]?.widthRect / dataCount;
  return (
      scaleCoordinates[index] && (
          <rect x={xCoord} y={y} width={barWidth} height={height} stroke="white" strokeWidth={0.5} fill={fill} />
      )
  );
};

export default CustomizedBar;