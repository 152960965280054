import { isEqual, isNil } from 'lodash';
import { JOB_FIELD } from '../../constants/Jobs';
import Condition from './converter/model/Condition';
import Filter from './converter/model/Filter';
import Operator from './converter/model/Operator';
// import Condition from "./converter/model/Condition";

export const customFieldsIds = {
    startDate: -4,
    endDate: -5,
    ancestor: -20,
    valid: -23,
    progress: -7,
    calendar: -21,
    duration: -17,
    durationAndUnit: -10,
    durationUnit: -14,
    order: -15,
    impact: -16,
    quantity: -11,
    quantityAndUnit: -18,
    workrateAndUnit: -19,
    workrate: -13,
    quantityUnit: -12,
    needs: -24,
    // TODO change to identity id
    identity: -1,
};
export const FIELD_INPUT_TYPE = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    TEXT: 'text',
    NUMBER: 'number',
    NUMBER_MIN_MAX: 'numberMinMax',
    NUMBER_MIN_MAX_SELECT: 'numberMinMaxAndSelect',
    SELECT: 'select',
    SEARCH_SELECT: 'searchSelect',
    MULTISELECT: 'multiselect',
    MULTI_TREESELECT: 'multiTreeSelect',
    SELECT_OR_INPUT: 'selectOrInput',
    TREESELECT_OR_INPUT: 'treeselectOrInput',
};

export const excludedFields = [
    customFieldsIds.duration,
    customFieldsIds.order,
    customFieldsIds.impact,
    customFieldsIds.workrateAndUnit,
    customFieldsIds.quantityAndUnit,
];

export const conditionEnum = (i18n) => ({
    DIFFERENT: { label: '!=', name: 'DIFFERENT' },
    EQUAL: { label: '==', name: 'EGAL' },
    LOWER: { label: '<', name: 'INFERIEUR' },
    LOWER_OR_EQUAL: { label: '<=', name: 'INFERIEUROUEGAL' },
    UPPER: { label: '>', name: 'SUPERIEUR' },
    UPPER_OR_EQUAL: { label: '>=', name: 'SUPERIEUROUEGAL' },
    LIKE: { label: i18n.t('querybuilder.condition_contains'), name: 'MULTICONTIENT' },
    NOT_LIKE: { label: i18n.t('querybuilder.condition_not_contains'), name: 'MULTINECONTIENTPAS' },
    MULTICONTAINS: { label: i18n.t('querybuilder.condition_contains'), name: 'MULTICONTIENT' },
    NOT_MULTICONTAINS: { label: i18n.t('querybuilder.condition_not_contains'), name: 'MULTINECONTIENTPAS' },
    MULTICONTAINS_STRICT: { label: '==', name: 'MULTICONTIENT_CHAINE' },
    NOT_MULTICONTAINS_STRICT: { label: '!=', name: 'MULTINECONTIENTPAS_CHAINE' },
});
export const conditionEnumNoTranslation = {
    DIFFERENT: { label: '!=', name: 'DIFFERENT' },
    EQUAL: { label: '==', name: 'EGAL' },
    LOWER: { label: '<', name: 'INFERIEUR' },
    LOWER_OR_EQUAL: { label: '<=', name: 'INFERIEUROUEGAL' },
    UPPER: { label: '>', name: 'SUPERIEUR' },
    UPPER_OR_EQUAL: { label: '>=', name: 'SUPERIEUROUEGAL' },
    LIKE: { label: 'Contient', name: 'MULTICONTIENT' },
    NOT_LIKE: { label: 'Contient pas', name: 'MULTINECONTIENTPAS' },
    MULTICONTAINS: { label: 'Contient', name: 'MULTICONTIENT' },
    NOT_MULTICONTAINS: { label: 'Contient pas', name: 'MULTINECONTIENTPAS' },
    MULTICONTAINS_STRICT: { label: 'Contient', name: 'MULTICONTIENT_CHAINE' },
    NOT_MULTICONTAINS_STRICT: { label: 'Contient pas', name: 'MULTINECONTIENTPAS_CHAINE' },
    
};

export const getCustomFieldInfo = (customField) => {
    // if ([customFieldsIds.startDate, customFieldsIds.endDate].includes(customField.id)) {
    //   return { type: 'date', data: 'date' };
    // }
    if (customField.id === customFieldsIds.startDate) {
        return { type: FIELD_INPUT_TYPE.START_DATE, data: 'date' };
    }
    if (customField.id === customFieldsIds.endDate) {
        return { type: FIELD_INPUT_TYPE.END_DATE, data: 'date' };
    }
    if (customField.id === customFieldsIds.progress) {
        return { type: FIELD_INPUT_TYPE.NUMBER_MIN_MAX, data: customFieldsIds.progress };
    }
    if (customField.id === customFieldsIds.quantity) {
        return { type: FIELD_INPUT_TYPE.NUMBER_MIN_MAX, data: customFieldsIds.quantity };
    }
    if (customField.id === customFieldsIds.workrate) {
        return { type: FIELD_INPUT_TYPE.NUMBER_MIN_MAX, data: customFieldsIds.workrate };
    }
    if (customField.id === customFieldsIds.durationAndUnit) {
        return { type: FIELD_INPUT_TYPE.NUMBER_MIN_MAX_SELECT, data: customFieldsIds.durationAndUnit };
    }
    if (customField.id === customFieldsIds.quantityAndUnit) {
        return { type: FIELD_INPUT_TYPE.NUMBER_MIN_MAX_SELECT, data: customFieldsIds.quantityAndUnit };
    }
    if (customField.id === customFieldsIds.calendar) {
        return { type: FIELD_INPUT_TYPE.SELECT, data: customFieldsIds.calendar };
    }
    if (customField.id === customFieldsIds.quantityUnit) {
        return { type: FIELD_INPUT_TYPE.SELECT, data: customFieldsIds.quantityUnit };
    }
    if (customField.id === customFieldsIds.durationUnit) {
        return { type: FIELD_INPUT_TYPE.SELECT, data: customFieldsIds.durationUnit };
    }
    if (customField.id === customFieldsIds.ancestor) {
        return { type: FIELD_INPUT_TYPE.SEARCH_SELECT, data: customFieldsIds.ancestor };
    }
    if (customField.id === customFieldsIds.valid) {
        return { type: FIELD_INPUT_TYPE.SELECT, data: customFieldsIds.valid };
    }
    if (customField.id === customFieldsIds.identity) {
        return { type: FIELD_INPUT_TYPE.TEXT, data: customFieldsIds.identity };
    }
    if (customField.name === JOB_FIELD) {
        return { type: FIELD_INPUT_TYPE.SELECT_OR_INPUT, data: JOB_FIELD };
    }
    if (customField.id === customFieldsIds.needs) {
        return { type: FIELD_INPUT_TYPE.TREESELECT_OR_INPUT, data: customFieldsIds.needs };
    }
    if (customField.type.choice) {
        return { type: FIELD_INPUT_TYPE.SELECT, data: 'choice' };
    }
    if (customField.type.numeric) {
        return { type: FIELD_INPUT_TYPE.NUMBER };
    }
    return { type: FIELD_INPUT_TYPE.TEXT, data: 'text' };
};
// export const getFieldType = (customField) => {
//   if ([customFieldsIds.startDate, customFieldsIds.endDate].includes(customField.id)) {
//       return {type:'date'};
//   }
//   if (customField.id === customFieldsIds.ancestor || customField.id === customFieldsIds.valid || customField.type.choice) {
//       return 'select';
//   }
//   if (customField.type.numeric) {
//     return 'number';
//   }
//   if (customField.name === JOB_FIELD) {
//     return 'multiselect'
//   }
//   return 'text';
// };

export const getOperatorsOfType = (customField, i18n) => {
    const conditions = conditionEnum(i18n);
    switch (getCustomFieldInfo(customField).data) {
        case customFieldsIds.needs:
        case JOB_FIELD:
            return [conditions.MULTICONTAINS_STRICT, conditions.NOT_MULTICONTAINS_STRICT, conditions.MULTICONTAINS, conditions.NOT_MULTICONTAINS];
        case customFieldsIds.valid:
            return [conditions.DIFFERENT, conditions.EQUAL];
        case customFieldsIds.identity:
        case 'choice':
        case 'text':
            return [conditions.DIFFERENT, conditions.EQUAL, conditions.LIKE, conditions.NOT_LIKE];
        case customFieldsIds.ancestor:
            return [conditions.MULTICONTAINS, conditions.NOT_MULTICONTAINS];
        case customFieldsIds.quantityUnit:
        case customFieldsIds.durationUnit:
        case customFieldsIds.calendar:
            return [conditions.EQUAL, conditions.DIFFERENT];
        case 'number':
        case 'date':
        default:
            // Number or Date
            return [
                conditions.DIFFERENT,
                conditions.EQUAL,
                conditions.LOWER,
                conditions.LOWER_OR_EQUAL,
                conditions.UPPER,
                conditions.UPPER_OR_EQUAL,
            ];
    }
};

export const combinators = (i18n) => [
    { name: 'ET', label: i18n.t('querybuilder.and') },
    { name: 'OU', label: i18n.t('querybuilder.or') },
];

export const getDefaultValue = (choices, field = 'value', isMultiple = false) => {
    const defaultVal = choices[0] ? choices[0][field] : null;
    if (defaultVal) return isMultiple ? [defaultVal] : defaultVal;
    return isMultiple ? undefined : '';
};

export const queryBuilderLabels = (i18n, prefix = 'querybuilder') => ({
    fields: {
        title: i18n.t(`${prefix}.fields`),
        placeholderName: '~',
        placeholderLabel: i18n.t(`${prefix}.choose_field`),
        placeholderGroupLabel: '------',
    },
    operators: {
        title: i18n.t(`${prefix}.operators`),
        placeholderName: '~',
        placeholderLabel: '------',
        placeholderGroupLabel: '------',
    },
    value: {
        title: i18n.t(`${prefix}.value`),
    },
    removeRule: {
        label: 'x',
        title: i18n.t(`${prefix}.remove_rule`),
    },
    removeGroup: {
        label: 'x',
        title: i18n.t(`${prefix}.remove_group`),
    },
    addRule: {
        label: i18n.t(`${prefix}.add_rule_plus`),
        title: i18n.t(`${prefix}.add_rule`),
    },
    addGroup: {
        label: i18n.t(`${prefix}.add_group_plus`),
        title: i18n.t(`${prefix}.add_group`),
    },
    combinators: {
        title: i18n.t(`${prefix}.combinators`),
    },
    // "notToggle": {
    //   "label": "Not",
    //   "title": "Invert this group"
    // },
    // "cloneRule": {
    //   "label": "⧉",
    //   "title": "Clone rule"
    // },
    // "cloneRuleGroup": {
    //   "label": "⧉",
    //   "title": "Clone group"
    // },
    // "dragHandle": {
    //   "label": "⁞⁞",
    //   "title": "Drag handle"
    // },
    // "lockRule": {
    //   "label": "🔓",
    //   "title": "Lock rule"
    // },
    // "lockGroup": {
    //   "label": "🔓",
    //   "title": "Lock group"
    // },
    // "lockRuleDisabled": {
    //   "label": "🔒",
    //   "title": "Unlock rule"
    // },
    // "lockGroupDisabled": {
    //   "label": "🔒",
    //   "title": "Unlock group"
    // }
});

export const defaultQuery = {
    combinator: 'ET',
    rules: [],
};

const convertOperatorChildren = (rules, timeUnits, customFields, isArchive) => {
    // Children
    const children = [];
    if (rules.length > 0) {
        rules.forEach((rule) => {
            if (Object.hasOwn(rule, 'combinator')) {
                const operatorChild = new Operator(rule.combinator, []);
                operatorChild.children = convertOperatorChildren(rule.rules, timeUnits, customFields, isArchive);
                children.push(operatorChild);
            }
            if (Object.hasOwn(rule, 'field')) {
                if (!isNil(rule.value)) {
                    let valueToSend = rule.value;
                    if (rule.field === customFieldsIds.needs && Array.isArray(rule.value)) {
                        valueToSend = rule.value[rule.value.length - 1];
                    }
                    if (typeof rule.value === 'object' && !Array.isArray(rule.value)) {
                        valueToSend = [Number(rule.value.value).toFixed(1), rule.value.unit].join(' ');
                        if (rule.field === customFieldsIds.durationAndUnit) {
                            const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(rule.value.unit));
                            valueToSend = rule.value.value * durationUnit.duration;
                        }
                    }
                    let fieldId = rule.field;
                    if (isArchive) {
                        // test if customField
                        const customField = customFields.find((i) => i.id === rule.field);
                        if (customField) {
                            fieldId = customField.realId;
                        }
                    }
                    const condition = new Condition(fieldId, rule.operator, valueToSend);
                    children.push(condition);
                }
            }
        });
    }
    return children;
};

export const queryBuilderToUserFilter = (query, timeUnits, customFields, isArchive) => {
    // Root Operator
    const rootOperator = new Operator(query.combinator, []);
    // Children
    if (query.rules.length > 0)
        rootOperator.children = convertOperatorChildren(query.rules, timeUnits, customFields, isArchive);
    const filter = new Filter('Filter', rootOperator);
    return filter;
};

export const isSameFilter = (filter1, filter2) => filter1.id === filter2.id && isEqual(filter1.data, filter2.data);

export const genericValidator = (rule) => !!rule.value;
export const dateValidator = (rule) => rule.value !== '';

export const choiceValidator = (rule, list, field = 'name') => !!list.find((i) => i[field] === rule.value);
export const simpleChoiceValidator = (rule, list) => {
    if (rule.condition === conditionEnumNoTranslation.EQUAL || rule.condition === conditionEnumNoTranslation.DIFFERENT) {
        return choiceValidator(rule, list, 'value');
    }
        return true;
};
export const multipleChoiceValidator = (rule) => !!rule.value || (rule.value ? rule.value?.length : -1) > 0;
// if (rule.value.length === 0) return false;
// let result = true;
// (rule.value || []).forEach((val) => {
//     result &&= !!list.find((i) => i[field] === val);
// });
// return result;

// export const jobValidator = (rule, list) => multipleChoiceValidator(rule, list, 'value');

export const isFieldExist = (fields, field) => !!fields.find((i) => i.name === field);

export const defaultValidator = (query) => {
    const result = {};

    const validateRule = () => {
        // Replace this with your custom implementation.
        // Inside this function, set `result[_rule.id] = true` for a valid
        // rule, or `{ valid: false, reasons: ['your', 'reasons', 'here'] }`
        // for an invalid rule.
    };

    const validateGroup = (rg) => {
        const reasons = [];
        if (rg.rules.length === 0) {
            reasons.push('groupInvalidReasons.empty');
        }
        /* istanbul ignore else */
        if (rg.id) {
            if (reasons.length) {
                result[rg.id] = { valid: false, reasons };
            } else {
                result[rg.id] = true;
            }
        }
        rg.rules.forEach((r) => {
            if (typeof r === 'string') {
                // Validation for this case was done earlier
            } else if ('rules' in r) {
                validateGroup(r);
            } else {
                validateRule(r);
            }
        });
    };

    validateGroup(query);

    return result;
    // You can return the result object itself like above, or if you just
    // want the entire query to be marked invalid if _any_ rules/groups are
    // invalid, return a boolean like this:
    //   return Object.values(result).map(rv => (typeof rv !== 'boolean')).includes(true);
    // That will return `true` if no errors were found.
};

export const externalValidator = (query, fieldsMap) => {
    const result = {};

    const validateRule = (rule) => {
        const field = fieldsMap[rule.field];
        if (!field) {
            result[rule.id] = { valid: false, reasons: 'Field not existing' };
            return;
        }
        if (field.inputType === FIELD_INPUT_TYPE.SELECT && simpleChoiceValidator(rule, field.values) === false) {
            result[rule.id] = { valid: false, reasons: 'Choice not existing' };
            return;
        }
        if (field.inputType === FIELD_INPUT_TYPE.TREESELECT_OR_INPUT && multipleChoiceValidator(rule) === false) {
            result[rule.id] = { valid: false, reasons: 'Invalid value' };
            return;
        }
        if (field.inputType === 'multiselect' && multipleChoiceValidator(rule, field.values, 'value') === false) {
            result[rule.id] = { valid: false, reasons: 'Choice not existing' };
            return;
        }
        result[rule.id] = true;
    };

    const validateGroup = (rg) => {
        const reasons = [];
        if (rg.rules.length === 0) {
            reasons.push('groupInvalidReasons.empty');
        }
        /* istanbul ignore else */
        if (rg.id) {
            if (reasons.length) {
                result[rg.id] = { valid: false, reasons };
            } else {
                result[rg.id] = true;
            }
        }
        rg.rules.forEach((r) => {
            if (typeof r === 'string') {
                // Validation for this case was done earlier
            } else if ('rules' in r) {
                validateGroup(r);
            } else {
                validateRule(r);
            }
        });
    };

    validateGroup(query);

    // return result;
    // You can return the result object itself like above, or if you just
    // want the entire query to be marked invalid if _any_ rules/groups are
    // invalid, return a boolean like this:
    return Object.values(result)
        .map((rv) => typeof rv !== 'boolean')
        .includes(true);
    // That will return `true` if no errors were found.
};

export default conditionEnum;
