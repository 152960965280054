import React, { useState, useEffect, useMemo } from 'react';
import { Cascader, Input } from 'antd';

const filterOptions = (options, searchText, field) => 
    options.filter((option) => {
        const hasMatch = option[field].toLowerCase().includes(searchText.toLowerCase());
        if (hasMatch) return true; // Match in current level
        // Check for matches in children (recursive)
        return option.children && filterOptions(option.children, searchText, field).length > 0;
    });


const TreeAutocomplete = ({ value, values, handleOnChange, fieldData }) => {
    const [searchText, setSearchText] = useState(value);

    const filteredOptions = searchText
        ? filterOptions(values, searchText[searchText.length - 1], fieldData.fieldNames.label)
        : values;

    const handleSearch = (event) => {
        if (event.target.value) {
            setSearchText([event.target.value]);
        } else {
            setSearchText(undefined);
            handleOnChange(undefined);
        }
    };

    useEffect(() => {
        if (searchText) handleOnChange(searchText);
    }, [searchText]);

    const inputValue = useMemo(() => {
      if (Array.isArray(searchText)) {
        return searchText[searchText?.length - 1]
      } 
      return searchText;
    }, [searchText])

    // const handleChange = (newValue) => {
    //   const selectedOption = findOptionByPath(options, newValue);
    //   onChange(selectedOption ? [selectedOption.value] : []);
    // };

    return (
        <>
            <Cascader
                expandTrigger="hover"
                changeOnSelect
                style={{ minWidth: 200 }}
                value={value}
                options={filteredOptions}
                onChange={(val) => setSearchText(val)}
                fieldNames={fieldData.fieldNames}
            >
                <div>
                    <Input
                        allowClear
                        style={{ minWidth: 200 }}
                        value={inputValue}
                        onChange={handleSearch}
                    />
                </div>
            </Cascader>
        </>
    );
};

export default TreeAutocomplete;
