import React from 'react';
import { PropTypes } from 'prop-types';
import { EditOutlined } from '@ant-design/icons';

const EditButton = ({ onClick, className }) => (
    <EditOutlined
        onClick={onClick}
        className={`${className} p-2 rounded-full bg-primary hover:bg-primary-light text-gray-800 transition-colors duration-300`}
    />
);

EditButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

EditButton.defaultProps = {
    className: '',
};

export default EditButton;
