import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { QueryBuilder } from 'react-querybuilder';
import { clone } from 'lodash';

import '../../assets/styles/vendor/querybuilder/query-builder.scss';
import {
    combinators,
    defaultValidator,
    isFieldExist,
    queryBuilderLabels,
} from './FilterUtils';

import CustomValueEditor from './querybuilder-editor/CustomValueEditor';
import CustomFieldEditor from './querybuilder-editor/CustomFieldEditor';

const FilterQueryBuilder = ({ currentFilterQuery, onQueryChange, fields }) => {
    const { i18n } = useTranslation();
    const [query, setQuery] = useState(null);

    useEffect(() => {
        setQuery(currentFilterQuery);
    }, [currentFilterQuery]);
    
    const handleQueryChange = (q) => {
        console.log('q', q, );
        setQuery(clone(q));
        onQueryChange(q);
    };
    const queryBuilderLocale = queryBuilderLabels(i18n);
    const customCombinators = combinators(i18n);
    return (
        query && fields.length > 0 && (
            <QueryBuilderAntD>
                <QueryBuilder
                    combinators={customCombinators}
                    controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                    fields={fields}
                    query={query}
                    translations={queryBuilderLocale}
                    onQueryChange={handleQueryChange}
                    controlElements={{ valueEditor: CustomValueEditor, fieldSelector: CustomFieldEditor }}
                    validator={defaultValidator}
                    getRuleClassname={(rule) => (isFieldExist(fields, rule.field) ? '' : 'queryBuilder-invalid')}
                />
            </QueryBuilderAntD>
        )
    );
};

export default FilterQueryBuilder;
