import React from 'react';

const CustomizedActiveDot = (props) => {
  const { fill, cy, width, height, dataKey, scaleCoordinates, index, stroke , handleActiveDotMouseHover, handleActiveDotMouseLeave } = props;

  return (
      scaleCoordinates[index] && (
          <circle
              r={6}
              onMouseOver={() => handleActiveDotMouseHover(dataKey)}
              onMouseLeave={() => handleActiveDotMouseLeave(dataKey)}
              stroke={stroke}
              strokeWidth="1"
              fill={fill}
              width={width}
              height={height}
              cx={scaleCoordinates[index].x}
              cy={cy}
              className="recharts-dot recharts-line-dot"
          />
      )
  );
};

export default CustomizedActiveDot;