/* eslint no-unused-vars: "off" */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ComposedChart,
    YAxis,
} from 'recharts';
import { useSelector } from 'react-redux';
import { SLICE_DATA_TYPE } from '../../constants/Needs';
import { HistogramChartContext } from '../../contexts/chart/HistogramChartContext';
import { getDurationUnit } from '../plannings/gantt_utils';
import { selectChartOptions } from '../../redux/slices/chart/need-chart.slice';

const YAxisDrawer = () => {
    const { chartRef } = useContext(HistogramChartContext);
    const needTranslation = useTranslation('translation', { keyPrefix: 'needs' }).t;
    const histogramTranslation = useTranslation('translation', { keyPrefix: 'histogram' }).t;
    const chartOptions = useSelector(selectChartOptions);

    const labelValue = useMemo(() => {
        if (chartOptions?.sliceDataType === SLICE_DATA_TYPE.EFFORT) {
            const timeUnit = getDurationUnit(chartOptions.effortTimeUnitId);
            return `${histogramTranslation('shown_data_effort')}: ${needTranslation('needs')} / ${timeUnit.name}`
        }
        return '';
    }, [chartOptions])
    const [config, setConfig] = useState(null);
    useEffect(() => {
        if (chartRef?.yAxisMap) {
            const YaxisConfig = chartRef.yAxisMap[0];
            const neededConfig = {};
            neededConfig.domain = YaxisConfig.domain;
            neededConfig.tickCount = YaxisConfig.tickCount;
            neededConfig.ticks = YaxisConfig.niceTicks;
            neededConfig.height = YaxisConfig.height;
            neededConfig.width = YaxisConfig.width;
            setConfig(neededConfig);
        }
    }, [chartRef]);
    return (
        config && (
            // <ResponsiveContainer style={{ height: `${config?.height}px`, width: `${config?.width}px` }}>
                <ComposedChart height={config?.height} width={config?.width} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                    <YAxis
                        {...config}
                        label={{value: labelValue, angle: -90, fontSize: 16, position: 'outsideBottomLeft'}}
                        padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
                        scale="linear"
                        fontSize={12}
                    />
                </ComposedChart>
            // </ResponsiveContainer>
        )
    );
};

export default YAxisDrawer;
