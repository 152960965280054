import React from 'react';
import moment from 'moment';


export const TooltipLabelDate = ({ label }) => (
  <p>
      <label>{moment.utc(label).format(window.dateStringFormat)}</label>
  </p>
);

export const TooltipContent = ({ payload }) => (
  payload.realValue > 0 && (
      <p>
          <label style={{ color: payload.color }}>{payload?.name}</label>
          {` : ${payload?.value}`}
      </p>)
);


