/* eslint no-unused-vars: "off" */
/* eslint-disable react/jsx-props-no-spreading */
// import moment from 'moment';
import * as d3 from 'd3';



export const xAxisDateFormat = (translation) => ({
    hours: {
        mainScale: 'HH',
        subScale: 'DD MMM',
        dhtmlxMainScaleFormat: '%H',
        dhtmlxSubScaleFormat: '%j %M',
        mainScaleUnit: 'hours',
        subScaleUnit: 'days'
    },
    days: {
        mainScale: 'DD',
        subScale: 'MMMM YYYY',
        dhtmlxMainScaleFormat: '%d',
        dhtmlxSubScaleFormat: '%F %Y',
        mainScaleUnit: 'days',
        subScaleUnit: 'month'
    },
    week: {
        mainScale: 'DD',
        subScale: `[${translation('week')}]WW - YYYY`,
        dhtmlxMainScaleFormat: '%d',
        dhtmlxSubScaleFormat: `${translation('week')}%w - %Y`,
        mainScaleUnit: 'days',
        subScaleUnit: 'week'
    },
    month: {
        mainScale: `[${translation('week')}]WW`,
        subScale: 'MMMM YYYY',
        dhtmlxMainScaleFormat: `${translation('week')}%w`,
        dhtmlxSubScaleFormat: '%F %Y',
        mainScaleUnit: 'week',
        subScaleUnit: 'month'
    },
    quarter: {
        mainScale: 'MMM',
        subScale: `[${translation('quarter')}]Q YYYY`,
        dhtmlxMainScaleFormat: '%M',
        mainScaleUnit: 'month',
        subScaleUnit: 'quarter'
    },
    year: {
        mainScale: 'MMM',
        subScale: 'YYYY',
        dhtmlxMainScaleFormat: '%M',
        dhtmlxSubScaleFormat: '%Y',
        mainScaleUnit: 'month',
        subScaleUnit: 'year'
    },
});
export const xAxisDateScaleFunction = {
    hours: () => d3.timeMinute.every(60),
    days: () => d3.timeDay.every(1),
    week: () => d3.timeMonday.every(1),
    month: () => d3.timeMonth.every(1),
    quarter: () => d3.timeMonth.every(3),
    year: () => d3.timeYear.every(1),
};


