import React, {useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import { defaultQuery } from './FilterUtils';

const AddFilterForm = ({ onNewFilter }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'filter_dropdown' });
  const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

  const formRef = useRef();
    const handleFormValidated = ({name}) => {
        onNewFilter({id: crypto.randomUUID(), name, data: defaultQuery, created: new Date().toISOString()});
        formRef.current.resetFields();
    };
    return (
        <Form layout="horizontal" ref={formRef} initialValues={{
          name: '',
        }} onFinish={handleFormValidated}>
            <Form.Item
                name="name"
                required
                rules={[
                    {
                        required: true,
                        message: t('name_empty'),
                    },
                ]}
                style={{ width: '200px' }}
            >
                <Input placeholder={t('filter_name')} />
            </Form.Item>
            <Form.Item>
                <Button size="small" type="primary" htmlType="submit">
                    {generalTranslation('add')}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddFilterForm;
