/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tree, Button } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { union, difference, isEqual } from 'lodash';
import { extractPropertyInTreeChildren } from '../../../helpers/array-filter';

// list must contain a tree list with key, children attributes
const TreeCheckableList = ({ list, defaultChecked,  handleCheckedItemChange, labelField }) => {
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    const [treeList, setTreeList] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);
    const [selectedNode, setSelectedNode] = useState();
    const [expandedKeys, setExpandedKeys] = useState([]);

    useEffect(() => {
        if (list && list.length > 0) {
            const defaultSelected = extractPropertyInTreeChildren(list, 'key', 'children');
              setExpandedKeys(defaultSelected);
              setSelectedItems(defaultChecked ? defaultChecked : defaultSelected);
              setTreeList(list);
              setSelectedNode(list[0]);
        }

    }, [list, defaultChecked]);

    useEffect(() => {
        if (!isEqual(defaultChecked, selectedItems) && selectedItems) {
            handleCheckedItemChange(selectedItems.map(i => Number(i)));
        }
    }, [selectedItems]);

    const handleOnCheckItem = (keys) => {
        setSelectedItems(keys.checked);
    };

    const handleCheckChildren = (nodeData) => {
        const childIds = extractPropertyInTreeChildren(nodeData.children, 'key', 'children');
        setSelectedItems(union(selectedItems, childIds));
    };

    const handleUnCheckChildren = (nodeData) => {
        const childIds = extractPropertyInTreeChildren(nodeData.children, 'key', 'children');
        setSelectedItems(difference(selectedItems, childIds));
    };

    const expandSelectedNode = () => {
        if (selectedNode.children?.length) {
            const childIds = extractPropertyInTreeChildren(selectedNode.children, 'key', 'children');
            setExpandedKeys(union(expandedKeys, childIds));
        }
    };
    const collapseSelectedNode = () => {
        if (selectedNode.children?.length) {
            const childIds = extractPropertyInTreeChildren(selectedNode.children, 'key', 'children');
            setExpandedKeys(difference(expandedKeys, childIds));
        }
    };

    return selectedItems && (
        <Card
            size="small"
            bordered={false}
            className='h-full'
            bodyStyle={{ overflowY: 'auto', display: 'flex', flexGrow: '1', flexDirection: 'column', height: '100%' }}
        >
          <div className="flex items-center mb-2 justify-center">
                    <Button
                        type="secondary"
                        className="flex items-center mr-2"
                        size="small"
                        icon={<ArrowUpOutlined />}
                        onClick={() => collapseSelectedNode()}
                    >
                        {generalTranslation('collapse_all')}
                    </Button>
                    <Button
                        type="secondary"
                        className="flex items-center"
                        size="small"
                        icon={<ArrowDownOutlined />}
                        onClick={() => expandSelectedNode()}
                    >
                        {generalTranslation('expand_all')}
                    </Button>
                </div>
            {treeList.length > 0 && (
                <div className='flex-grow'>

                  <Tree
  
                      checkable
                      checkStrictly
                      selectable
                      treeData={treeList}
                      defaultExpandAll
                      showLine
                      selectedKeys={selectedNode ? [selectedNode.key] : []}
                      expandedKeys={expandedKeys}
                      checkedKeys={selectedItems}
                      onSelect={(a, e) => setSelectedNode(e.node)}
                      onExpand={(a, e) => setExpandedKeys(a)}
                      onCheck={handleOnCheckItem}
                      titleRender={(node) =>
                        node.children?.length ? (
                            <div className="flex items-center" style={{minHeight: '24px'}}>
                                <label className="mr-3">{node[labelField]}</label>
                                <div className="tree-actions">
                                    <Button
                                        title={generalTranslation('check_children')}
                                        type="primary"
                                        onClick={() => handleCheckChildren(node)}
                                        size="small"
                                        icon={<CheckSquareOutlined />}
                                    />
                                    <Button
                                        title={generalTranslation('uncheck_children')}
                                        type="primary"
                                        onClick={() => handleUnCheckChildren(node)}
                                        size="small"
                                        icon={<CloseSquareOutlined />}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                {node.isLevel ? (
                                    <label>{node[labelField]}</label>
                                ) : (
                                    <div className="flex items-center">
                                        <span className="mr-3">{node[labelField]}</span>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: node.color,
                                            }}
                                        ></div>
                                    </div>
                                )}
                            </>
                        )
                      }
                  />
                </div>
            )}
        </Card>
    );
};
export default TreeCheckableList;
