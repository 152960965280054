

import React , {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HistogramTabChartOptions from './HistogramTabChartOptions';
import HistogramTabHistogram from './HistogramTabHistogram';
import { selectProcessingGantt } from '../../redux/slices/app/planning.slice';
import computeActivityCalendarSliceDictionary from '../../redux/thunks/activity-calendar-slice.thunk';
// import { SliceContextProvider } from '../../contexts/slicing/SliceContext';
// import { NeedChartContextProvider } from '../../contexts/chart/ChartContext';
const HistogramTabChartContainer = () => {
    const dispatch = useDispatch();
    const processingGantt= useSelector(selectProcessingGantt)
    useEffect(() => {
      
    if (processingGantt === false)
        dispatch(computeActivityCalendarSliceDictionary());
      
    }, [processingGantt])
    

    return processingGantt === false && (
        // <SliceContextProvider>
            // <NeedChartContextProvider>
                <div style= {{background: 'white'}} className="w-full h-full flex flex-grow overflow-hidden pt-2">
                    <div
                        className="h-full overflow-auto flex justify-between border-solid border-2 border-sky-500"
                        id="gantt-histogram-options"
                    >
                            <HistogramTabChartOptions/>
                        
                    </div>

                    <div
                        className="h-full border-solid flex-grow overflow-hidden border-2 border-sky-500 "
                        id="gantt-histogram-area"
                    >
                        <div id="gantt-histogram-container" className="h-full">
                            <HistogramTabHistogram />
                        </div>
                    </div>
                </div>
            // </NeedChartContextProvider>
        // </SliceContextProvider>
    );
};

export default HistogramTabChartContainer;
