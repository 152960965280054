import React from 'react';
import { Curve } from 'recharts';
import { generateString } from '../../../helpers/string-helper';

const CustomizedLines = ({ formattedGraphicalItems, coordinates }) => {
  const linesPointsToDraw = [];
  formattedGraphicalItems.forEach((graphicalItems) => {
      const points = graphicalItems.props.points.flatMap((point, index) => {
          if (coordinates[index]) {
              const newPoint = { ...point, x: coordinates[index]?.x };
              return newPoint;
          }
          return [];
      });
      linesPointsToDraw.push({ points, stroke: graphicalItems.item.props.stroke });
  });
  return linesPointsToDraw.map(({ points, stroke }) => (
      <Curve key={generateString(8)} points={points} stroke={stroke} strokeWidth={1} fill="none" />
  ));
};

export default CustomizedLines;