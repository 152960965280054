import React from "react";

const CustomizedDot = (props) => {
  const { fill, cy, r, width, height, name, scaleCoordinates, index, stroke, handleActiveDotMouseLeave } = props;

  return (
      scaleCoordinates[index] && (
          <circle
              r={r}
              onMouseLeave={() => handleActiveDotMouseLeave(name)}
              stroke={stroke}
              strokeWidth="1"
              fill={fill}
              width={width}
              height={height}
              cx={scaleCoordinates[index].x}
              cy={cy}
              className="recharts-dot recharts-line-dot"
          />
      )
  );
};
export default CustomizedDot;